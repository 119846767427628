<template>
  <component-debug :component="this" />
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style lang="scss" scoped>
</style>
